@font-face {
  font-family: "Lato";
  src: url("./assets/Lato-Black.ttf");
  color: #34448f;
}
@font-face {
  font-family: "Clash Display";
  src: url("./assets/fonts/clash display.ttf");
  font-style: normal;
  /* font-weight: bold; */
}
* {
  font-family: "Clash Display";
  background-color: "#FFFFFF";
}
.clash-display {
  font-family: "Clash Display";
}
.clash-font-family {
  font-family: "Clash Display";
}
:root {
  font-family: "Lato";
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
}
.root {
  width: inherit;
  height: inherit;
}

.navlink {
  padding: 7px 10px;
  text-decoration: none;
}
.nav-right {
}
.sidebar-footer {
  display: block;
  width: 100%;
}
a {
  outline: none;
  text-decoration: none;
  width: 100%;
}
.sidebar-link {
  color: #21233799;
  padding: 12px 16px;
}
a.sidebar-link:hover,
a.sidebar-link:active {
  background: #263b950d;
  border-right: 4px solid #263b95;
  font-weight: 500;
}

.sidebar-link:hover span {
  color: #263b95;
}
.sidebar-link:hover span svg {
  stroke: #263b95;
}
a.sidebar-link:hover,
a.sidebar-link:active span svg path {
  stroke: #263b95;
}
.sidebar-link:hover span svg path {
  stroke: #263b95;
}
.navlink-right {
  padding: 1rem;
}
a.sidebar-link:hover span:last-of-type,
a.sidebar-link:active span:last-of-type {
  font-weight: 500;
  color: #212337;
}

.sidebar-footer {
}

.dashboardpage {
  width: 100%;
  height: 100%;
  overflow-y: visible;
}

/* custom dropdown select  */
.drop-down-input {
  cursor: pointer;
}
.drop-down-input input {
  width: 70%;
  background: none;
}
.drop-down-input button {
  font-size: 20px;
}

.inputs::placeholder {
  font-weight: 700;
}

#auth-modal {
  max-width: 500px;
  position: absolute;
}

/* card number */
.card-number::nth-character {
}
